<template>
  <div v-loading="loading">
    <el-select
      :value="value"
      :placeholder="label"
      default-first-option
      @change="onChange"
    >
      <el-option label="Régimen Simple" value="RS">
        <span style="float: left">Régimen Simple</span>
      </el-option>
      <el-option label="Responsable de IVA" value="RV">
        <span style="float: left">Responsable de IVA</span>
      </el-option>
      <el-option label="Ninguno" value="NA">
        <span style="float: left">Ninguno</span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import select, { model } from './common'

export default {
  extends: select('', 'contributor'),
  data() {
    return model()
  },
}
</script>
