import { render, staticRenderFns } from "./register-with-person.vue?vue&type=template&id=f68d39c8&scoped=true"
import script from "./register-with-person.vue?vue&type=script&lang=js"
export * from "./register-with-person.vue?vue&type=script&lang=js"
import style0 from "./register-with-person.vue?vue&type=style&index=0&id=f68d39c8&prod&lang=css"
import style1 from "./register-with-person.vue?vue&type=style&index=1&id=f68d39c8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f68d39c8",
  null
  
)

export default component.exports